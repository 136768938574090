import styled from 'styled-components';

export default styled.div`
  border-radius: 4px;
  .swiper-recommend {
    height: 460px;
  }
  .recommend-item {
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .recommend-banner-img {
      width: 100%;
      height: auto;
    }
    .is-over-height {
      height: 100%;
      width: auto;
    }
    .commend-blog-description {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      color: #fff;
      padding: 0 2.5%;
      .blog-title {
        margin-top: 25%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .blog-digest {
        line-height: 20px;
        margin-top: 0.9%;
        width: 100%;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .create-time-container {
        margin-top: 2%;
        display: flex;
        align-items: center;
        .admin-text {
          margin-left: 8px;
        }
        .logo-container {
          display: inline-block;
          width: 24px;
          height: 24px;
          img {
            height: 24px;
            width: 24px;
            overflow: hidden;
            border-radius: 50%;
          }
        }
        .create-time {
          padding-left: 26px;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .swiper-recommend {
      height: 36vw;
    }
    .blog-digest {
      width: 100%;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @media screen and (max-width: 750px) {
    margin: 0 16px;
    .blog-title {
      margin-top: 21% !important;
    }
    .create-time-container {
      display: none !important;
    }
  }
`;
