import React, { useState, useEffect } from 'react';
import Style from './style';
import PageTitle from '../../components/base/page-title';
import BlogCategory from './components/blog-category';
import BlogListItem from './components/blog-list-item';
import BlogRecommend from './components/blog-recommend';
import shopApi from 'api/shop';
import { useIntl } from 'react-intl';
import { MiniPagination } from 'zent';

const Blogs = (props) => {
  const { initialData = {} } = props;
  const intl = useIntl();
  const [categoryId, setCategoryId] = useState('');
  const [pageInfo, setPageInfo] = useState({ pageSize: 10, current: 1, total: 0 });
  const [blogList, setBlogList] = useState(initialData?.pageData?.items || []);
  const [blogAllNum, setBlogAllNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shopLogoUrl, setShopLogoUrl] = useState('');
  const shopName = initialData?.shopBasicInfo?.name;
  const onPageChange = (data) => {
    if (loading) return;
    const { current } = data;
    setPageInfo({ ...pageInfo, current });
  };
  // 获取店铺logo
  useEffect(() => {
    shopApi.getShopLogo().then((res) => {
      setShopLogoUrl(res?.shopLogoUrl);
    });
  }, []);

  // 获取博客列表
  const getBlogList = (props = {}) => {
    const { current, id } = props;
    const params = {
      pageSize: pageInfo.pageSize,
      page: current || pageInfo.current,
      statusList: ['visible'],
    };
    setLoading(true);
    const currentCategoryId = id === undefined ? categoryId : id;
    shopApi
      .searchWithPageBlog(currentCategoryId ? { ...params, categoryId: currentCategoryId } : params)
      .then((res) => {
        const { items, paginator } = res;
        if (!id) setBlogAllNum(items?.length);
        setPageInfo({
          ...pageInfo,
          total: paginator?.totalCount,
        });
        setBlogList(items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCategoryChange = (id) => {
    setCategoryId(id);
    getBlogList({ current: 1, id });
  };

  useEffect(() => {
    getBlogList();
  }, [pageInfo.current]);

  return (
    <Style>
      <div className='page-title-container'>
        <PageTitle
          list={[
            { text: intl.formatMessage({ defaultMessage: '首页', id: 'store.edit.homepage' }), href: '/' },
            { text: intl.formatMessage({ defaultMessage: '博客', id: 'blog.app.name' }), href: '/blogs' },
          ]}
        />
      </div>
      <div className='recommend-wrapper'>
        <BlogRecommend shopLogoUrl={shopLogoUrl} />
      </div>
      <div className='blog-home-container'>
        <div className='blog-kind'>
          {blogAllNum > 0 && (
            <div className='blog-kind-container'>
              <BlogCategory setCategoryId={onCategoryChange} categoryId={categoryId} />
            </div>
          )}
        </div>

        <div className='blogs-list-container'>
          {blogList.map((item) => {
            return <BlogListItem blog={item} shopLogoUrl={shopLogoUrl} shopName={shopName} />;
          })}
          {pageInfo.total === 0 && (
            <div className='empty-blog-container'>
              <svg width={60} height={60} fill='#969799'>
                <use xlinkHref='#iconic-kong' />
              </svg>
              <div className='empty-blog-tips'>
                {intl.formatMessage({
                  defaultMessage: '暂无文章博客',
                  id: '8dfa4b1bc54c4619b234bc366be64390',
                })}
              </div>
            </div>
          )}
          {pageInfo.total > 10 && (
            <div className='pagination-container'>
              <MiniPagination
                current={pageInfo.current}
                pageSize={pageInfo.pageSize}
                total={pageInfo.total}
                onChange={onPageChange}
              />
            </div>
          )}
        </div>
      </div>
    </Style>
  );
};

export default Blogs;
