import styled from 'styled-components';

export default styled.div`
  max-width: ${(props) => (props.theme?.containerWidth ? `${props.theme?.containerWidth}px` : '1280px')};
  margin-bottom: 60px;
  .blog-home-container {
    display: flex;
    margin-top: var(--pm-24-16);
    .blog-kind-container {
      width: 220px;
      flex-grow: 0;
      margin-right: 24px;
      background: #fff;
      border-radius: 4px;
      padding: var(--pm-24-16);
    }
    .blogs-list-container {
      width: 220px;
      flex-grow: 10;
      background: #fff;
      border-radius: 4px;
      padding: 24px;
    }
  }
  .page-title-container {
    margin-top: 24px;
  }
  .zent-number-input.zent-input-wrapper {
    width: 56px !important;
    min-width: 56px !important;
  }
  .zent-input-wrapper .zent-input {
    min-width: 56px !important;
  }
  .pagination-container {
    margin-top: 30px;
  }
  .empty-blog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 492px;
    .empty-blog-tips {
      margin-top: 8px;
      color: #969799;
      margin-top: 16px;
    }
  }
  .recommend-wrapper {
    margin-top: 24px;
  }

  @media screen and (max-width: 750px) {
    .recommend-wrapper {
      margin-top: 16px;
    }
    .page-title-container {
      padding: 0 16px;
      margin-top: 12px;
    }
    .blog-home-container {
      flex-direction: column;

      .blog-kind-container {
        margin-right: 0;
        min-height: 0;
        padding: 12px 12px;
        margin: 0 16px;
        overflow: auto;
        width: calc(100% - 32px);
        box-sizing: border-box;
      }
      .blogs-list-container {
        width: calc(100% - 32px);
        padding: 12px 0;
        margin: 16px 16px 0;
        box-sizing: border-box;
      }
    }
    .pagination-container {
      padding-right: 8px;
      margin-top: 16px;
    }
  }
`;
