import styled from 'styled-components';

export default styled.div`
  .blogs-item {
    display: flex;
    border-bottom: 1px solid #ebedf0;
    padding-bottom: 16px;
    .blog-title-image {
      width: 183px;
      height: 183px;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
    }
    .blog-intro-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 24px;
      max-width: 781px;
      .author-container {
        display: flex;
        align-items: center;

        .admin-text {
          color: #323233;
          margin-left: 8px;
        }
        .shop-logo-container {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 24px;
            height: 24px;
            overflow: hidden;
            border-radius: 50%;
          }
        }
      }
      .blog-intro-title {
        color: #000;
        font-weight: 600;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .blog-intro-content {
        color: #646566;
        line-height: 20px;
        margin-top: 12px;
        word-break: break-word;
      }
      .time-container {
        color: #969799;
        display: flex;
        font-size: 12px;
        align-items: center;
        margin-top: 12px;
        height: 17px;
        svg {
          padding-right: 6px;
        }
      }
      .category-container {
        margin-top: 12px;
        span {
          display: inline-block;
          padding: 2px 4px;
          color: #969799;
          background: #ebedf0;
          border-radius: 2px;
          font-size: 12px;
        }
      }
    }
  }

  &:not(:first-child) {
    padding-top: 16px;
  }

  @media screen and (max-width: 750px) {
    .blogs-item {
      .blog-intro-container {
        margin-left: 16px;
        padding-right: 12px;
        max-width: 230px;
        .author-container {
          margin-top: 8px;
          display: flex;
          align-items: center;
          svg {
            height: 14px;
            width: 14px;
          }
          .shop-logo-container {
            width: 14px;
            height: 14px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-height: 14px;
              max-height: 14px;
            }
          }
        }
        .time-container {
          margin-top: 8px;
        }
        .blog-intro-content {
          margin-top: 4px;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
        }
        .category-container {
          display: none;
        }
      }
      .blog-title-image {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        background-position: center;
        background-size: cover;
        flex-shrink: 0;
      }
    }
  }
`;
