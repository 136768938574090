import Style from './style';
import shopApi from 'api/shop.js';
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

const BlogCategory = (props) => {
  const hashId = window.location.hash || '';
  const { setCategoryId, categoryId } = props;
  const [categoryList, setCategoryList] = useState([]);
  const intl = useIntl();
  const getBlogCategoryList = () => {
    shopApi.getBlogCategorys().then((data) => {
      setCategoryList(data);
    });
  };

  const onCategorySelect = (id) => {
    window.location.hash = id;
    setCategoryId(id);
  };

  useEffect(() => {
    const id = hashId?.replace('#', '');
    setCategoryId(id);
    getBlogCategoryList();
  }, []);

  return (
    <Style>
      <div className='category-title'>
        {intl.formatMessage({ defaultMessage: '分类', id: 'd0771a42bbc49a6941f59913fcda35e3' })}
      </div>
      <div
        className={cn('category-item all-item', { 'category-item-active': !categoryId })}
        onClick={() => {
          onCategorySelect('');
        }}
      >
        {intl.formatMessage({ defaultMessage: '全部', id: 'general.all' })}
      </div>
      {categoryList.map((item) => {
        return (
          <div
            onClick={() => {
              onCategorySelect(item?.categoryId);
            }}
            className={cn('category-item', { 'category-item-active': categoryId === item?.categoryId })}
          >
            {item?.name}
          </div>
        );
      })}
    </Style>
  );
};

export default BlogCategory;
