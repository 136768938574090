import styled from 'styled-components';

export default styled.div`
  .category-title {
    color: #969799;
    font-size: 12px;
    line-height: 17px;
    padding-bottom: 4px;
  }
  .category-item {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #323233;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .category-item-active {
    font-size: 14px;
    font-weight: 700;
    color: #323233;
  }

  @media screen and (max-width: 750px) {
    display: flex;
    .category-title {
      display: none;
    }
    .blog-kind-container {
      width: 100% !important;
      min-height: auto !important;
    }
    .category-item {
      word-break: keep-all;
      white-space: nowrap;
      margin-right: 16px;
      margin-top: 0;
      overflow: visible;
      display: inline-block;
    }
  }
`;
